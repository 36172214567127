import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { isDesktop } from 'react-device-detect';
import Animation from '../../../ui/lottie';

const SectionMethod: FC<{ translation: TFunction }> = ({ translation }) => {
  const animationMobile = translation('sectionRecommend.lottie_mobile', {
    returnObjects: true,
  });
  const animation = translation('sectionRecommend.lottie', {
    returnObjects: true,
  });

  return (
    <section className="section section-method ">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-centered is-7 vertical-align">
            <h4 className="method-title title has-text-centered title-large  has-text-white ">
              {translation('sectionRecommend.title')}
            </h4>
            <p className="method-text has-text-white has-text-centered text-medium">
              {translation('sectionRecommend.text')}
            </p>
          </div>
        </div>
      </div>
      <div className="columns is-centered is-hidden-desktop">
        <div className="column is-centered is-7 vertical-align">
          <Animation
            name={translation(animationMobile)}
            className="has-text-centered method-animation is-paddingless overflow mobile  "
          />
        </div>
      </div>

      {isDesktop && (
        <div className="columns is-centered">
          <div className="column is-centered has-text-centered vertical-align">
            <Animation
              name={translation(animation)}
              className="method-animation overflow desktop is-hidden-mobile "
            />
          </div>
        </div>
      )}
    </section>
  );
};

export { SectionMethod };
