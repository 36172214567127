/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore-start
import * as React from 'react';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CardFloatImage } from '../partials/common/elements/Card';
import Button from '../ui/button';
import pages from '../../utils/pages';

interface StyledProps {
  block?: string;
  height?: number;
  size?: string;
  className?: any;
}
const { getPageUrl } = pages;

const SwitchButton = styled.button<StyledProps>`
  width: 190px;
  height: 35px;
  padding: 6px 54px 6px 55px;
  border-radius: 20px;
  color: #222;
  text-align: center;
  margin: 50px -20px 25px;
  border: solid 1px rgba(189, 195, 199, 0.6);
  transition: background-color 0.5s;
  background-color: #f6f7fa;
  &.active {
    border: none;
    width: 150px;
    height: 35px;
    padding: 6px 54px 6px 55px;
    border-radius: 20px;
    background-color: #222;
    color: white;
    z-index: 1;
    position: relative;
    transition: background-color 0.2s;
  }
`;

const Features: FC<{
  data: any;
  group?: string;
  translation?: any;
}> = ({ data, translation, group }) => {
  const { t } = useTranslation('general');
  const [selectedGroup, setGroup] = useState(group);
  return (
    <section
      className={`${data.classes && data.classes} section section-feauters`}
    >
      <div className="container">
        <div className="columns is-centered  ">
          <div className="column is-9 is-centered">
            <h2 className="title title-large has-text-centered text-black">
              {data.title}
            </h2>
            {data.text && (
              <p className=" has-text-centered text-black small_subtitle">
                {data.text}
              </p>
            )}
            <div className="is-centered switch_row text-centered">
              {data.groups &&
                Object.keys(data.groups).length > 1 &&
                Object.keys(data.groups).map((key) => {
                  return (
                    <SwitchButton
                      key={key}
                      onClick={() => setGroup(key)}
                      type="button"
                      onKeyDown={() => setGroup(key)}
                      className={key === selectedGroup && 'active'}
                    >
                      {data.groups[key].group_name}
                    </SwitchButton>
                  );
                })}
            </div>

            {data.groups &&
              data.groups[selectedGroup] &&
              data.groups[selectedGroup].price && (
                <div className="price_row is-centered text-centered">
                  <p>
                    {translation('prefix')}
                    <span className="price">
                      {data.groups[selectedGroup].price}
                    </span>
                    / {translation('period')}
                  </p>
                </div>
              )}
          </div>
        </div>
        <div className=" is-centered columns">
          <div className="column is-11 feature-cards columns is-centered">
            {data.groups &&
              data.groups[selectedGroup] &&
              data.groups[selectedGroup].title && (
                <h2 className="title title-medium has-text-centered text-black">
                  {data.groups[selectedGroup].title}
                </h2>
              )}

            {data.groups &&
              data.groups[selectedGroup] &&
              Object.keys(data.groups[selectedGroup].features).map((key) => {
                const feature = data.groups[selectedGroup].features[key];
                return (
                  <div
                    key={key}
                    className={`feature_wrap is-6 column ${key}`}
                    id={`card_${key}`}
                  >
                    <CardFloatImage
                      card={feature}
                      key={key}
                      classes="feature-card text-centered"
                    />
                  </div>
                );
              })}
          </div>
        </div>
        {data.ctaOff ? (
          <></>
        ) : (
          <div className="cta-wrapper columns is-centered">
            <div className="column is-8 is-12-mobile has-text-centered no-pad">
              <Button to={getPageUrl('demo', t('key')).url}>
                {t('get_consult')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

Features.defaultProps = {
  group: 'group_1',
};

export default Features;
