import * as React from 'react';
import { FC } from 'react';
import { Image } from '../../../ui/image';
import images from '../../../../utils/store/images';
import { ImageGallery } from '../../../ui/imageGallery';

const SectionColumns: FC<{
  columns: any;
}> = ({ columns }) => {
  return (
    <section className="section section-benefits ">
      <div className="container">
        <div className="columns is-centered benefits-images">
          <div className="column is-6 columns is-centered is-mobile">
            {Object.keys(images.pay).map((key) => {
              const { alt, img, width, height } = images.pay[key];
              return (
                <div
                  className={`column is-3-mobile payment-icon is-paddingless-mobile ${key} `}
                  key={key}
                >
                  <ImageGallery
                    height={height}
                    width={width}
                    name={img}
                    alt={alt}
                    className=" "
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="columns ">
          {Object.keys(columns).map((key) => {
            const { text, alt, title } = columns[key];
            return (
              <div
                key={key}
                className="column benefit-card is-one-third is-12-mobile"
              >
                <div className="">
                  <div className="benefit-icon has-text-centered">
                    <Image name={key} alt={alt || ''} className="column-icon" />
                  </div>
                  <div className="benefits-text-container">
                    <h2 className="title-small">{title}</h2>
                    <p className="text-medium">{text}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SectionColumns;
