import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageProps, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { HeroPay } from '../components/partials/common/hero';
import SectionAdvantage from '../components/partials/pay/section-advantage';
import SectionColumns from '../components/partials/pay/section-benefits';
import { SectionMethod } from '../components/partials/pay/section-method';
import NewRepeatingSections from '../components/sections/new-repeating-section';
import Features from '../components/sections/features';
import DemoConversionSection from '../components/partials/pay/section-conversion';
import SectionFaq from '../components/sections/faq-section';
import Shop from '../components/partials/pricing/shop';

const ReferralPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  const { lang, alternateUrls } = pageContext;
  const { t } = useTranslation(localKey);

  const sections: IColumnList = t('sections', {
    returnObjects: true,
  });

  const mobileImagesHero: any = t('hero.image-mobile', {
    returnObjects: true,
  });

  const listbenefitsForYou: IColumnList = t('sectionBenefits', {
    returnObjects: true,
  });

  const features: any = t('features', {
    returnObjects: true,
  });

  const questions: IColumnList = t('faq.questions', {
    returnObjects: true,
  });

  return (
    <Layout pageContext={pageContext}>
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroPay translation={t} mobileImagesHero={mobileImagesHero} />
      <SectionAdvantage translation={t} />
      <SectionMethod translation={t} />
      <SectionColumns columns={listbenefitsForYou} />
      <NewRepeatingSections data={sections} />
      <Features data={features} />
      <DemoConversionSection translation={t} />
      <SectionFaq
        classes={t('faq.classes')}
        title={t('faq.title')}
        questions={questions}
      />
      {lang !== 'en' &&
        lang !== 'fr' &&
        lang !== 'en-US' &&
        lang !== 'de-CH' &&
        lang !== 'es' && (
          <Shop
            data={t}
            title="Du möchtest dein Kassenzubehör <br> direkt online bestellen?"
          />
        )}
    </Layout>
  );
};

export default ReferralPage;
