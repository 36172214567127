/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { isMobileOnly } from 'react-device-detect';
import HubspotForm from 'react-hubspot-form';
import { Image } from '../../../ui/image';
import { ImageGallery } from '../../../ui/imageGallery';
import TrustPilot from '../../widgets/TrustPilot';
import images from '../../../../utils/store/images';
import Divider from '../../../ui/divider';

const ConversionSection: FC<{ translation: TFunction }> = ({ translation }) => {
  const leftList: any = translation('conversion.leftList', {
    returnObjects: true,
  });
  const { shorePayPeople } = images;

  return (
    <section className=" section-conversion" id="sales">
      <div className="container">
        <div className="columns is-centered  ">
          <div className="column is-6 is-centered">
            <h2 className="title title-large has-text-centered has-text-white">
              {translation('conversion.title')}
            </h2>
          </div>
        </div>
        <div className="columns is-centered has-text-centered">
          <div className="column is-10-desktop  has-background-white conversion-wrapper is-centered">
            <div className="columns">
              {!isMobileOnly && (
                <div className="column web-demo">
                  <p className="  text-black">
                    {translation('conversion.titleLeft')}
                  </p>
                  <div className="image-container image-list">
                    {Object.keys(shorePayPeople).map((key: any) => {
                      return (
                        <ImageGallery
                          key={key}
                          name={shorePayPeople[key]}
                          alt="we support you"
                          className="sales_person"
                          maxWidth="70"
                          height="70"
                        />
                      );
                    })}
                  </div>
                  <div className="is-flex">
                    <ul>
                      {Object.keys(leftList).map((key) => {
                        return (
                          <li key={key}>
                            <Image
                              name="check_black.svg"
                              className="inline-svg inline-icon"
                              alt="shore.com"
                            />
                            {leftList[key]}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="columns trust-row is-hidden-mobile ">
                    <div className="column ">
                      <div className="trust-box">
                        <TrustPilot />
                      </div>
                      <div className="trust-box">
                        <ImageGallery
                          alt="trust icon"
                          className="trust-content"
                          name="shore_trust_seals_01_made_in_germany.png"
                          maxWidth="300"
                          height="225"
                        />
                      </div>
                    </div>
                  </div>

                  <ImageGallery
                    name={translation('conversion.imageLeft')}
                    alt="bottom card image"
                    className="bottom-image is-hidden-mobile "
                  />
                </div>
              )}
              <Divider />

              {isMobileOnly && (
                <div className="column mobile-demo-intro">
                  <p className="  text-black">
                    {translation('conversion.titleLeft')}
                  </p>
                </div>
              )}

              <div className="column web-demo ">
                <div className="hbspt-form-wrapper">
                  <div className="hb-target hbspt-form" id="hbspt-form-id">
                    <HubspotForm
                      portalId={translation('conversion.form.portalId')}
                      formId={translation('conversion.form.formId')}
                      loading={<div>Loading...</div>}
                    />
                  </div>
                </div>
              </div>
              {isMobileOnly && (
                <>
                  <div className="column web-demo">
                    <div className="columns trust-row is-hidden-desktop has-text-centered">
                      <div className="column is-centered is-vcentered">
                        <div className="trust-box">
                          <TrustPilot />
                        </div>
                        <div className="trust-box">
                          <ImageGallery
                            alt="trust icon"
                            className="trust-content"
                            name="shore_trust_seals_01_made_in_germany.png"
                            maxWidth="300"
                            height="225"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <ImageGallery
                    name={translation('conversion.imageLeft')}
                    alt="bottom card image"
                    className="bottom-image is-hidden-desktop "
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConversionSection;
