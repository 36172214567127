import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import { Image } from '../../../ui/image';

const SectionAdvantage: FC<{ translation: TFunction }> = ({ translation }) => {
  const cards = translation('sectionAdvantage.cards', {
    returnObjects: true,
  });

  return (
    <section className="section section-advantage has-background-grey">
      <h4 className="advantage-title title has-text-centered title-medium ">
        {translation('sectionAdvantage.title')}
      </h4>
      <div className="container ">
        <div className="columns is-variable is-centered is-3">
          {Object.keys(cards).map((key) => {
            const { text, classN } = cards[key];
            return (
              <div
                key={key}
                className="column advantage-card is-3-desktop is-12-mobile"
              >
                <div className="card">
                  <div className="advantage-icon has-text-centered">
                    <Image
                      name={key}
                      alt="advantage icons"
                      className={` ${classN} icon`}
                    />
                  </div>
                  <div className="advantage-text-container has-text-centered">
                    <p className="text-medium has-text-white">{text}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SectionAdvantage;
